import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';

const ExternalLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.primary};
`;

const InternalLink = styled(GatsbyLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.primary};
`;

export default function Link({ to, href, ...props }) {
  if (to) {
    return <InternalLink to={to} {...props} />;
  } else {
    return <ExternalLink href={href} {...props} />;
  }
}

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-platform-body-jsx": () => import("./../../../src/pages/platform/body.jsx" /* webpackChunkName: "component---src-pages-platform-body-jsx" */),
  "component---src-pages-platform-header-jsx": () => import("./../../../src/pages/platform/header.jsx" /* webpackChunkName: "component---src-pages-platform-header-jsx" */),
  "component---src-pages-platform-index-js": () => import("./../../../src/pages/platform/index.js" /* webpackChunkName: "component---src-pages-platform-index-js" */),
  "component---src-pages-platform-solutions-jsx": () => import("./../../../src/pages/platform/solutions.jsx" /* webpackChunkName: "component---src-pages-platform-solutions-jsx" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-services-devops-jsx": () => import("./../../../src/pages/services/devops.jsx" /* webpackChunkName: "component---src-pages-services-devops-jsx" */),
  "component---src-pages-services-icon-jsx": () => import("./../../../src/pages/services/icon.jsx" /* webpackChunkName: "component---src-pages-services-icon-jsx" */),
  "component---src-pages-services-index-body-jsx": () => import("./../../../src/pages/services/index-body.jsx" /* webpackChunkName: "component---src-pages-services-index-body-jsx" */),
  "component---src-pages-services-index-card-jsx": () => import("./../../../src/pages/services/index-card.jsx" /* webpackChunkName: "component---src-pages-services-index-card-jsx" */),
  "component---src-pages-services-index-header-jsx": () => import("./../../../src/pages/services/index-header.jsx" /* webpackChunkName: "component---src-pages-services-index-header-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-services-integration-jsx": () => import("./../../../src/pages/services/integration.jsx" /* webpackChunkName: "component---src-pages-services-integration-jsx" */),
  "component---src-pages-services-migration-jsx": () => import("./../../../src/pages/services/migration.jsx" /* webpackChunkName: "component---src-pages-services-migration-jsx" */),
  "component---src-pages-services-service-body-item-jsx": () => import("./../../../src/pages/services/_service_/body-item.jsx" /* webpackChunkName: "component---src-pages-services-service-body-item-jsx" */),
  "component---src-pages-services-service-body-jsx": () => import("./../../../src/pages/services/_service_/body.jsx" /* webpackChunkName: "component---src-pages-services-service-body-jsx" */),
  "component---src-pages-services-service-header-jsx": () => import("./../../../src/pages/services/_service_/header.jsx" /* webpackChunkName: "component---src-pages-services-service-header-jsx" */),
  "component---src-pages-services-service-index-js": () => import("./../../../src/pages/services/_service_/index.js" /* webpackChunkName: "component---src-pages-services-service-index-js" */),
  "component---src-pages-services-training-jsx": () => import("./../../../src/pages/services/training.jsx" /* webpackChunkName: "component---src-pages-services-training-jsx" */),
  "component---src-pages-solutions-ai-jsx": () => import("./../../../src/pages/solutions/ai.jsx" /* webpackChunkName: "component---src-pages-solutions-ai-jsx" */),
  "component---src-pages-solutions-automation-jsx": () => import("./../../../src/pages/solutions/automation.jsx" /* webpackChunkName: "component---src-pages-solutions-automation-jsx" */),
  "component---src-pages-solutions-data-jsx": () => import("./../../../src/pages/solutions/data.jsx" /* webpackChunkName: "component---src-pages-solutions-data-jsx" */),
  "component---src-pages-solutions-devops-jsx": () => import("./../../../src/pages/solutions/devops.jsx" /* webpackChunkName: "component---src-pages-solutions-devops-jsx" */),
  "component---src-pages-solutions-education-jsx": () => import("./../../../src/pages/solutions/education.jsx" /* webpackChunkName: "component---src-pages-solutions-education-jsx" */),
  "component---src-pages-solutions-enterprise-jsx": () => import("./../../../src/pages/solutions/enterprise.jsx" /* webpackChunkName: "component---src-pages-solutions-enterprise-jsx" */),
  "component---src-pages-solutions-healthcare-jsx": () => import("./../../../src/pages/solutions/healthcare.jsx" /* webpackChunkName: "component---src-pages-solutions-healthcare-jsx" */),
  "component---src-pages-solutions-index-body-jsx": () => import("./../../../src/pages/solutions/index-body.jsx" /* webpackChunkName: "component---src-pages-solutions-index-body-jsx" */),
  "component---src-pages-solutions-index-card-jsx": () => import("./../../../src/pages/solutions/index-card.jsx" /* webpackChunkName: "component---src-pages-solutions-index-card-jsx" */),
  "component---src-pages-solutions-index-header-jsx": () => import("./../../../src/pages/solutions/index-header.jsx" /* webpackChunkName: "component---src-pages-solutions-index-header-jsx" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-nesis-body-jsx": () => import("./../../../src/pages/solutions/nesis/body.jsx" /* webpackChunkName: "component---src-pages-solutions-nesis-body-jsx" */),
  "component---src-pages-solutions-nesis-header-jsx": () => import("./../../../src/pages/solutions/nesis/header.jsx" /* webpackChunkName: "component---src-pages-solutions-nesis-header-jsx" */),
  "component---src-pages-solutions-nesis-index-js": () => import("./../../../src/pages/solutions/nesis/index.js" /* webpackChunkName: "component---src-pages-solutions-nesis-index-js" */),
  "component---src-pages-solutions-nesis-qanda-jsx": () => import("./../../../src/pages/solutions/nesis/qanda.jsx" /* webpackChunkName: "component---src-pages-solutions-nesis-qanda-jsx" */),
  "component---src-pages-solutions-sme-jsx": () => import("./../../../src/pages/solutions/sme.jsx" /* webpackChunkName: "component---src-pages-solutions-sme-jsx" */),
  "component---src-pages-solutions-solution-body-item-jsx": () => import("./../../../src/pages/solutions/_solution_/body-item.jsx" /* webpackChunkName: "component---src-pages-solutions-solution-body-item-jsx" */),
  "component---src-pages-solutions-solution-body-jsx": () => import("./../../../src/pages/solutions/_solution_/body.jsx" /* webpackChunkName: "component---src-pages-solutions-solution-body-jsx" */),
  "component---src-pages-solutions-solution-header-jsx": () => import("./../../../src/pages/solutions/_solution_/header.jsx" /* webpackChunkName: "component---src-pages-solutions-solution-header-jsx" */),
  "component---src-pages-solutions-solution-index-js": () => import("./../../../src/pages/solutions/_solution_/index.js" /* webpackChunkName: "component---src-pages-solutions-solution-index-js" */)
}


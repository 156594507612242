import { wrapRootElement as wrap } from './root-wrapper';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';

require('whatwg-fetch');

export const wrapPageElement = wrap;

export const onClientEntry = () => {
  // Don't need to do anything here, but if you don't
  // export something, the import won't work.
};

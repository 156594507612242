import React from 'react';
import { ThemeProvider } from 'styled-components';
import Layout from './src/components/Layout';
import theme from './src/utils/theme';

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <Layout>{element}</Layout>
  </ThemeProvider>
);
